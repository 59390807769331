@use "sass:map";
@use "sass:string";

$fonts: (
  "light":          ("style": "normal","weight": "300","file": "../fonts/roboto-v27-latin-300.woff2"),
  "light-italic":   ("style": "italic","weight": "300","file": "../fonts/roboto-v27-latin-300italic.woff2"),
  "regular":        ("style": "normal","weight": "400","file": "../fonts/roboto-v27-latin-regular.woff2"),
  "regular-italic": ("style": "italic","weight": "400","file": "../fonts/roboto-v27-latin-italic.woff2"),
  "medium":         ("style": "normal","weight": "500","file": "../fonts/roboto-v27-latin-500.woff2"),
  "medium-italic":  ("style": "italic","weight": "500","file": "../fonts/roboto-v27-latin-500italic.woff2"),
  "bold":           ("style": "normal","weight": "700","file": "../fonts/roboto-v27-latin-700.woff2"),
  "bold-italic":    ("style": "italic","weight": "700","file": "../fonts/roboto-v27-latin-700italic.woff2"),
);

@each $label, $font in $fonts {
    $font-url: map.get($font, "file");
    /* #{$label} */
    @font-face{
        font-family: "Roboto";
        font-style: string.unquote(map.get($font,"style"));
        font-weight: string.unquote(map.get($font,"weight"));
        font-display: swap;
        src: url($font-url) format("woff2");
    }
}
