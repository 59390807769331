.dv-header {
    display:flex;
    flex-direction:column;
}
.dv-blade {
    background: $ua-green;
    color:white;
    padding:1rem 0;
    > .container, > .container-fluid {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        justify-content: space-between;
    }
    .dv-ua-logo{
        margin:0;
    }
    .navbar-toggler{
        display:inline-block;
        margin-left:-1 * $navbar-toggler-padding-x;
        color:inherit;

        @include media-breakpoint-up(lg){
            display:none;
        }
    }
}
.dv-blade-title{
    flex:1 0 100%;
    order:3;
    padding:1rem 0 0 0;
    @include media-breakpoint-up(lg){
        margin-left:1rem;
        flex:1 0 0;
        padding: $navbar-brand-padding-y 0;
    }
}
.dv-blade-buttons{
    width: 3.5rem;
    text-align: center;

    order:2;
    @include media-breakpoint-up(lg){
        order:3;
    }
    .btn{
        color:inherit;
    }

    .nav-search-button{
        .show-shown{
            display:none;
        }
        &.show{
            .show-shown{
                display:inline-block;
            }
            .show-hidden{
                display:none;
            }
        }
    }
}
.mega-nav {
    border-radius:0;
    min-width:100vw!important;
    max-width:100%!important;
    border:none;
    box-shadow: 0 10px 9px rgba(0,0,0,.2);

    .dv-giant-search{
        display:flex;
        align-items:center;
    }
}
ul.dv-quick-links {
    line-height: 1rem;
    list-style: none;

    li {
        margin: 1.25rem 0;
    }

    a {
        color: $body-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
