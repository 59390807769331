/**
 * Contains styling for the main layout page. And other general layout styling
 */
 :root{
  // Needed to prevent Chrome from hiding validation balloons when smooth scrolling
  scroll-behavior: auto;
}
html {
  height: 100%;
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  padding: 2.813rem 0;
  flex: 1 0 auto;
}

@media (max-width: 575.98px) {
  .content {
    padding: 1rem 0;
  }
}

.dv-footer {
  flex: 0 0 auto;
}

.dv-ua-logo {
  margin: 1rem 1rem 1rem 0;
  background-size: 100% 100%;
  width: 12rem;
  height: 3rem;
  background-image: url('../img/UA_Logo_WHT_RGB.svg')
}

@include media-breakpoint-down(sm) {
  .container {
    width: 100%;
    max-width: none;
  }
}