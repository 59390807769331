// Relative to the "css" directory
$fa-font-path: "../fonts/icons";  

@import '../node_modules/@fortawesome/fontawesome-free/scss/variables';
@import '../node_modules/@fortawesome/fontawesome-free/scss/mixins';
@import '../node_modules/@fortawesome/fontawesome-free/scss/core';
@import '../node_modules/@fortawesome/fontawesome-free/scss/larger';
@import '../node_modules/@fortawesome/fontawesome-free/scss/fixed-width';
// @import '../node_modules/@fortawesome/fontawesome-free/scss/list';
@import '../node_modules/@fortawesome/fontawesome-free/scss/bordered-pulled';
@import '../node_modules/@fortawesome/fontawesome-free/scss/animated';
@import '../node_modules/@fortawesome/fontawesome-free/scss/rotated-flipped';
// @import '../node_modules/@fortawesome/fontawesome-free/scss/stacked';
@import '../node_modules/@fortawesome/fontawesome-free/scss/screen-reader';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands';


//
// IMPORTANT!! Any changes to this list must be mirrored in /icons.js
// 
// Keys in this map must match the values in that file's "icons" array
// 
// Icons marked with "EA" are used (not necessarily exclusively) on the EA homepage
//
$icons: (
    angle-double-down: $fa-var-angle-double-down,
    angle-double-left: $fa-var-angle-double-left,
    angle-double-right: $fa-var-angle-double-right,
    angle-double-up: $fa-var-angle-double-up,
    angle-down: $fa-var-angle-down,
    angle-left: $fa-var-angle-left,
    angle-right: $fa-var-angle-right,
    angle-up: $fa-var-angle-up,
    arrow-left: $fa-var-arrow-left,
    arrow-right: $fa-var-arrow-right,
    ban: $fa-var-ban,
    bars: $fa-var-bars,
    building: $fa-var-building,
    caret-down: $fa-var-caret-down,
    caret-up: $fa-var-caret-up,
    certificate: $fa-var-certificate,
    chart-line: $fa-var-chart-line,
    check: $fa-var-check,
    circle: $fa-var-circle,
    code: $fa-var-code,
    comments: $fa-var-comments,
    carrot: $fa-var-carrot,
    download: $fa-var-download,
    edit: $fa-var-edit,
    ellipsis-v: $fa-var-ellipsis-v,
    envelope: $fa-var-envelope,
    exclamation-triangle: $fa-var-exclamation-triangle,
    external-link-alt: $fa-var-external-link-alt,
    eye: $fa-var-eye,
    eye-slash: $fa-var-eye-slash,
    facebook: $fa-var-facebook,
    file-alt: $fa-var-file-alt,
    file-download: $fa-var-file-download,
    file-pdf: $fa-var-file-pdf,
    file-upload: $fa-var-file-upload,
    flag: $fa-var-flag,
    folder: $fa-var-folder,
    globe: $fa-var-globe,
    graduation-cap: $fa-var-graduation-cap,
    linkedin: $fa-var-linkedin,
    lock: $fa-var-lock,
    map-marked-alt: $fa-var-map-marked-alt,
    minus: $fa-var-minus,
    orcid: $fa-var-orcid,
    phone: $fa-var-phone,
    plus: $fa-var-plus,
    question-circle: $fa-var-question-circle,
    search: $fa-var-search,
    spinner: $fa-var-spinner,
    star: $fa-var-star,
    sort: $fa-var-sort,
    sync-alt: $fa-var-sync-alt,
    times: $fa-var-times,
    times-circle: $fa-var-times-circle,
    trash: $fa-var-trash,
    twitter-square: $fa-var-twitter-square,
    twitter:$fa-var-twitter,
    university: $fa-var-university,
    unlock: $fa-var-unlock,
    upload: $fa-var-upload,
    users: $fa-var-users
);

@each $key, $value in $icons {
    .#{$fa-css-prefix}-#{$key}:before {
        content: fa-content($value);
    }
}